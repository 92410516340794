import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { clientRoutes } from "./utils/routes";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "./colors.scss";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("./pages/home/Home"));
const VerifyEmail = lazy(() => import("./pages/verifyEmail/VerifyEmail"));
const EmailSuccessScreen = lazy(
  () => import("./pages/EmailSuccess/EmailSuccessScreen")
);

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path={clientRoutes.home} element={<Home />} />
          <Route
            path={clientRoutes.emailSuccessSplashScreen}
            element={<EmailSuccessScreen />}
          />
          <Route path={clientRoutes.verifyEmail} element={<VerifyEmail />} />
          <Route
            path={"*"}
            element={
              <>
                <p>Page not found!</p>
              </>
            }
          />
        </Routes>
      </Suspense>

      <ToastContainer />
    </div>
  );
}

export default App;